import React from "react"
import { Link, graphql } from "gatsby"

import PostsComponent from "../components/posts"
import Layout from "../components/layout"

export const query = graphql`
  query PostsAndCategories {
    posts: allStrapiPost(sort: {fields: [published] order: DESC}) {
      edges {
        node {
          id
          slug
          title
          published
          content
          card_image_description
          card_image {
            publicURL
          }
          categories {
            name
            slug
          }
        }
      }
    }
    categories: allStrapiCategory {
      edges {
        node {
          id
          name
          strapiId
          slug
        }
      }
    }
  }
`

const Category = ({ data }) => {
  const posts = data.posts.edges
  const categories = data.categories.edges

  return (
    <Layout title="Post Categories">
      {categories.map(category =>
        {
          return (
            <div className="uk-section">
              <div className="uk-container uk-container-small">
                <Link to={`/category/${category.node.slug}`}>
                    <h1 className="ui header">{category.node.name}</h1>
                </Link>
                <hr />
                <PostsComponent posts={posts.map(post => (
                    post.node.categories.some(postCategory => (
                        category.node.name === postCategory.name)
                    ) ? post : null
                )).filter(post => post !== null)} />
              </div>
            </div>
          )
        })
      }
    </Layout>
  )
}

export default Category