import React from "react"
import { Link } from "gatsby"

import Moment from "react-moment"

const Card = ({ post }) => {
  return (
    <Link to={`/post/${post.node.slug}`} className="uk-link-reset">
      <div className="uk-card uk-card-muted">
        <div className="uk-card-media-top">
          <img className="card-image"
            src={post.node.card_image?.publicURL}
            alt={post.node.card_image?.publicURL}
            title={post.node.card_image_description}
            height="100"
          />
        </div>
        <div id="card-category-badge" className="uk-card-badge uk-label">
          {post.node.categories.map((category, i, categories) =>
              {
                return (
                  <Link to={`/category/${category.slug}`}
                  className="uk-link-reset">{category.name}{categories[i+1] && " | "}</Link>
                )
              }
            )
          }
        </div>
        <div className="uk-card-body">
          <span id="card-body-info" className="uk-align-left">
            <p id="card-title" className="uk-text-large">
              {post.node.title}
            </p>
            <p id="card-publish-date" className="uk-text-small">
              <Moment format="MMM Do, YYYY">{post.node.published}</Moment>
            </p>
          </span>
        </div>
      </div>
    </Link>
  )
}

export default Card